import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const paymentRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.addCredits,
    async () => await import('@/pages/payment/AddCreditsPage'),
  ),
  createRoleProtectedRouteObject(
    routes.orderConfirmation,
    async () => await import('@/pages/payment/OrderConfirmationPage'),
  ),
]
