import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import { Outlet } from 'react-router-dom'

import { Link } from '@/components/Link'
import { ThinkLifeLogo } from '@/components/ThinkLifeLogo'
import { MainCard } from '@/components/berry/MainCard'
import { routes } from '@/configs/routes'

const lightGradient =
  'linear-gradient(20deg, rgba(245,245,245,1) 0%, rgba(249,224,255,1) 80%, rgba(229,128,255,1) 100%);'
const darkGradient =
  'linear-gradient(20deg, rgba(10,10,10,1) 0%, rgba(51,0,64,1) 75%, rgba(102,0,128,1) 100%);'

export function AuthLayout() {
  return (
    <Box
      sx={{
        background: (theme) =>
          theme.palette.mode === 'dark' ? darkGradient : lightGradient,
        minHeight: '100vh',
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: '100vh' }}
      >
        <Grid size={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 'calc(100vh - 68px)' }}
          >
            <Grid sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <MainCard
                sx={{
                  'maxWidth': { xs: 400, lg: 475 },
                  'margin': { xs: 2.5, md: 3 },
                  '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%',
                  },
                }}
              >
                <Box sx={{ p: { xs: 0, sm: 1, xl: 3 } }}>
                  <Grid container spacing={4}>
                    <Grid size={12}>
                      <ThinkLifeLogo size="large" />
                    </Grid>

                    <Grid size={12}>
                      <Outlet />
                    </Grid>

                    <Grid size={12}>
                      <Stack
                        direction="row"
                        spacing={4}
                        justifyContent="center"
                      >
                        <Link
                          to={routes.termsAndConditions.path}
                          underline="hover"
                        >
                          Terms and Conditions
                        </Link>
                        <Link to={routes.privacyPolicy.path} underline="hover">
                          Privacy Policy
                        </Link>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
