import AssignmentIcon from '@mui/icons-material/AssignmentOutlined'
import InfoIcon from '@mui/icons-material/Info'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const agencyRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.agencies,
    async () => await import('@/pages/contacts/AgenciesPage'),
  ),
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.agencyGeneral.path,
          },
          {
            label: 'Commissions',
            icon: <RequestQuoteIcon />,
            linkTo: routes.agencyCommissions.path,
          },
          {
            label: 'Contracting',
            icon: <AssignmentIcon />,
            linkTo: routes.agencyContracting.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.agencyGeneral,
        async () => await import('@/pages/contacts/AgencyGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.agencyCommissions,
        async () => await import('@/pages/contacts/CommissionsPage'),
      ),
      createRoleProtectedRouteObject(
        routes.agencyContracting,
        async () => await import('@/pages/UnimplementedPage'),
      ),
    ],
  },
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.agencyCommissionGeneral.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.agencyCommissionGeneral,
        async () => await import('@/pages/contacts/CommissionGeneralPage'),
      ),
    ],
  },
]
