import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const salesRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.prospects,
    async () => await import('@/pages/SalesPages/ProspectsPage'),
  ),
  createRoleProtectedRouteObject(
    routes.prospectDetails,
    async () => await import('@/pages/SalesPages/ProspectDetailsPage'),
  ),
  createRoleProtectedRouteObject(
    routes.pipeline,
    async () => await import('@/pages/SalesPages/PipelinePage'),
  ),
  createRoleProtectedRouteObject(
    routes.quotes,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.applications,
    async () => await import('@/pages/UnimplementedPage'),
  ),
]
