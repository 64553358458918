import HomeIcon from '@mui/icons-material/Home'
import Box from '@mui/material/Box'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import {
  generatePath,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom'

import { Link, type LinkProps } from '@/components/Link'

interface RouteData {
  name: string
  path: string
}

export interface BreadcrumbProps {
  routes: Record<string, RouteData>
  homePath: string
}

export function Breadcrumbs({ routes, homePath }: BreadcrumbProps) {
  const location = useLocation()
  const params = useParams()

  const crumbs: RouteData[] = useMemo(() => {
    const matchedKeys: string[] = []
    const matchedRoutes: RouteData[] = []

    for (const [key, value] of Object.entries(routes)) {
      if (
        matchPath(
          { path: value.path, end: false, caseSensitive: true },
          location.pathname,
        ) != null &&
        !matchedKeys.includes(key)
      ) {
        matchedKeys.push(key)
        matchedRoutes.push(value)
      }
    }
    return matchedRoutes
  }, [location.pathname, routes])

  const lastCrumb = crumbs[crumbs.length - 1]

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Typography variant="h3">{lastCrumb.name}</Typography>
          <Box flexGrow={1} />
          <MuiBreadcrumbs aria-label="breadcrumb" separator=">">
            <Link to={homePath}>
              <HomeIcon fontSize="small" sx={{ marginTop: 0.5 }} />
            </Link>
            {crumbs.map((crumb, i) => {
              const sharedProps: Pick<LinkProps, 'variant'> = {
                variant: 'body2',
              }
              return i < crumbs.length - 1 ? (
                <Link
                  key={crumb.name}
                  underline="hover"
                  to={generatePath(crumb.path, params)}
                  {...sharedProps}
                >
                  {crumb.name}
                </Link>
              ) : (
                <Typography key={crumb.name} {...sharedProps}>
                  {crumb.name}
                </Typography>
              )
            })}
          </MuiBreadcrumbs>
        </Box>
      </CardContent>
    </Card>
  )
}
