import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const calendarRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.myCalendar,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.tasks,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.reminders,
    async () => await import('@/pages/UnimplementedPage'),
  ),
]
