import { type TypedDocumentNode, gql } from '@apollo/client'

import type {
  CurrentUserQuery,
  CurrentUserQueryVariables,
} from './currentUserQuery.generated'

export const CURRENT_USER_QUERY: TypedDocumentNode<
  CurrentUserQuery,
  CurrentUserQueryVariables
> = gql`
  query CurrentUser {
    me {
      agentApplications {
        edges {
          node {
            id
            status
          }
        }
      }
      creditBalance
      id
      profile {
        agent {
          agencyAdmin
          id
          upline {
            id
            ... on Agency {
              name
            }
          }
        }
        customer {
          id
        }
        email
        firstName
        id
        lastName
        phoneNumber
      }
      timeZone
    }
  }
`
