import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { firebaseAuth } from './firebase'

const authLink = setContext(async (_, { headers }) => {
  const token = await firebaseAuth.currentUser?.getIdToken()
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      ...(token != null && {
        authorization: `Bearer ${token}`,
      }),
    },
  }
})

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_API_URL,
})

export const graphqlClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
})
