import { useLazyQuery, useMutation } from '@apollo/client'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { captureException } from '@sentry/react'
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { type ReactNode, useEffect, useMemo, useState } from 'react'

import { AuthenticationContext } from './AuthenticationContext'
import { CURRENT_USER_QUERY } from './gql/currentUserQuery'
import { UPDATE_ME_MUTATION } from './gql/updateMe'
import type { AuthenticationContextValue } from './types'

import { LoadingSpinner } from '@/components/LoadingSpinner'
import { firebaseAuth } from '@/configs/firebase'
import { graphqlClient } from '@/configs/graphql'
import { signOut } from '@/lib/firebaseHelpers'

export interface AuthProviderProps {
  children?: ReactNode
}

export function AuthenticationProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] =
    useState<AuthenticationContextValue['currentUser']>()
  const [authUser, setAuthUser] =
    useState<AuthenticationContextValue['authUser']>()
  const [idTokenResult, setIdTokenResult] =
    useState<AuthenticationContextValue['idTokenResult']>(null)
  const [providers, setProviders] =
    useState<AuthenticationContextValue['providers']>(null)
  const [error, setError] = useState<unknown>()

  const [getCurrentUser, { data }] = useLazyQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only', // always get up-to-date user
  })
  const [updateMe] = useMutation(UPDATE_ME_MUTATION)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      async function processUser() {
        setAuthUser(user)
        if (user != null) {
          setProviders(user.providerData)
          const { error } = await getCurrentUser()
          if (error != null) {
            captureException(error)
          }
          setError(error)
        } else {
          setCurrentUser(null)
          setProviders(null)
          await graphqlClient.clearStore()
        }
      }

      processUser().catch((error) => {
        captureException(error)
        setError(error)
      })
    })
    return unsubscribe
  }, [getCurrentUser])

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebaseAuth, (user) => {
      async function processToken() {
        if (user != null) {
          // Set the id token result to get claims
          const idToken = await user.getIdTokenResult()
          setIdTokenResult(idToken)
        } else {
          setIdTokenResult(null)
        }
      }

      processToken().catch((error) => {
        captureException(error)
        setError(error)
      })
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    if (data != null && idTokenResult != null) {
      setCurrentUser(data.me)
    }
  }, [data, idTokenResult])

  // Update timezone for the user if it isn't already set.
  useEffect(() => {
    if (currentUser != null && currentUser.timeZone == null) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      void updateMe({ variables: { input: { timeZone } } })
    }
  }, [currentUser, updateMe])

  const value: AuthenticationContextValue | undefined = useMemo(
    () =>
      currentUser === undefined || authUser === undefined
        ? undefined
        : {
            currentUser,
            authUser,
            idTokenResult,
            providers,
          },
    [authUser, currentUser, idTokenResult, providers],
  )

  if (error != null) {
    return (
      <Stack
        height="100vh"
        alignItems="center"
        display="flex"
        spacing={3}
        marginTop={8}
      >
        <Typography variant="h3">Oops!</Typography>
        <Box>
          {import.meta.env.DEV ? (
            <>
              <Typography>Error while getting current user:</Typography>
              {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
              <Typography>{String(error)}</Typography>
              {idTokenResult != null && (
                <Button onClick={signOut}>Log Out</Button>
              )}
            </>
          ) : (
            <>
              <Typography>An unknown error has occurred.</Typography>
              <Typography>Please try refreshing the page.</Typography>
              <Button onClick={signOut}>Log Out</Button>
            </>
          )}
        </Box>
      </Stack>
    )
  }

  return value == null ? (
    <Box height="100vh">
      <LoadingSpinner />
    </Box>
  ) : (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  )
}
