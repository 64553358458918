import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const invitationsRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.invitations,
    async () => await import('@/pages/contacts/InvitationsPage'),
  ),
]
