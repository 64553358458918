import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import themes from '@think-life/mui-theme'
import { type ReactNode, useCallback, useMemo, useState } from 'react'

import { ThemeConfigContext } from './ThemeConfigContext'
import type { ThemeConfigContextValue } from './types'

export interface ThemeConfigProviderProps {
  children?: ReactNode
}

export function ThemeConfigProvider({ children }: ThemeConfigProviderProps) {
  const [mode, setMode] = useState<ThemeConfigContextValue['mode']>('light')

  const toggleMode: ThemeConfigContextValue['toggleMode'] = useCallback(() => {
    setMode((currentMode) => (currentMode === 'light' ? 'dark' : 'light'))
  }, [])

  const value: ThemeConfigContextValue = useMemo(
    () => ({
      mode,
      toggleMode,
    }),
    [mode, toggleMode],
  )

  const theme = mode === 'dark' ? themes.dark : themes.light

  return (
    <ThemeConfigContext.Provider value={value}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              pre: { backgroundColor: '#EEEEEE', padding: 20 },
              code: { backgroundColor: '#EEEEEE', padding: 2 },
            }}
          />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeConfigContext.Provider>
  )
}
