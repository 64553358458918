import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { carriersRoutes } from './carriers'

import { routes } from '@/configs/routes'

export const systemManagementRoutes: RouteObject[] = [
  ...carriersRoutes,

  createRoleProtectedRouteObject(
    routes.users,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.permissions,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.pendingUsers,
    async () => await import('@/pages/systemManagement/AgentApplicationsPage'),
  ),
  createRoleProtectedRouteObject(
    routes.commissions,
    async () => await import('@/pages/UnimplementedPage'),
  ),
]
