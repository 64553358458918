import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid2'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useCallback } from 'react'
import {
  Outlet,
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

interface TabData {
  label: string
  icon: string | React.ReactElement
  linkTo: string
}

export interface DetailsLayoutProps {
  tabs?: TabData[]
}

export function DetailsLayout({ tabs = [] }: DetailsLayoutProps) {
  const { pathname } = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const tabIndex = tabs.findIndex((tab) => {
    const fullPath = generatePath(tab.linkTo, params)
    const matches = matchPath({ path: fullPath, caseSensitive: true }, pathname)

    return matches != null
  })

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, value: number) => {
      const tab = tabs[value] as TabData | undefined
      if (tab != null) {
        navigate(generatePath(tab.linkTo, params))
      }
    },
    [navigate, params, tabs],
  )

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Card>
          <CardHeader
            disableTypography
            title={
              <Tabs value={tabIndex} onChange={handleTabChange}>
                {tabs.map(({ label, icon, linkTo }, ind) => (
                  <Tab
                    key={ind}
                    label={label}
                    icon={icon}
                    iconPosition="start"
                    href={generatePath(linkTo, params)}
                    onClick={(event) => {
                      event.preventDefault()
                    }}
                  />
                ))}
              </Tabs>
            }
          />
          <CardContent>
            <Outlet />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
