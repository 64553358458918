import type { AuthenticationContextValue } from '@/providers/AuthenticationProvider/types'

export function userLoggedIn(auth: AuthenticationContextValue): boolean {
  return auth.idTokenResult != null
}

export function userHasRoles(auth: AuthenticationContextValue): boolean {
  return auth.idTokenResult?.claims.roles?.[0] != null
}

export function userHasApplication(auth: AuthenticationContextValue): boolean {
  return auth.currentUser?.agentApplications.edges[0] != null
}

export function userRequiresApplication(
  auth: AuthenticationContextValue,
): boolean {
  return !userHasRoles(auth) && !userHasApplication(auth)
}

export function userWaitingOnApplication(
  auth: AuthenticationContextValue,
): boolean {
  return !userHasRoles(auth) && userHasApplication(auth)
}
