import { type TypedDocumentNode, gql } from '@apollo/client'

import type {
  UpdateMeMutation,
  UpdateMeMutationVariables,
} from './updateMe.generated'

export const UPDATE_ME_MUTATION: TypedDocumentNode<
  UpdateMeMutation,
  UpdateMeMutationVariables
> = gql`
  mutation UpdateMe($input: UpdateUserInput!) {
    updateMe(updateMeInput: $input) {
      id
      timeZone
    }
  }
`
