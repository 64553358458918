import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const leadReturnsRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.leadReturns,
    async () => await import('@/pages/leadManagement/LeadReturnsPage'),
  ),
]
