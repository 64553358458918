import type { LazyRouteFunction, RouteObject } from 'react-router-dom'

import { RoleProtectedRoute } from './RoleProtectedRoute'

import type { RouteData } from '@/configs/routes'

export function createRoleProtectedRouteObject(
  routeData: RouteData,
  lazy: LazyRouteFunction<RouteObject>,
): RouteObject {
  const { path, allowedRoles } = routeData
  return {
    element: <RoleProtectedRoute allowedRoles={allowedRoles} />,
    children: [
      {
        path,
        lazy,
      },
    ],
  }
}
