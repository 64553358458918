import Box, { type BoxProps } from '@mui/material/Box'

export interface ImageProps extends BoxProps {
  src: string
  alt: string
}

export function Image({ src, alt, ...rest }: ImageProps) {
  return <Box {...rest} component="img" src={src} alt={alt} />
}
