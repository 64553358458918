import InfoIcon from '@mui/icons-material/Info'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const carriersRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.carriers,
    async () => await import('@/pages/systemManagement/CarriersPage'),
  ),
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.carrierGeneral.path,
          },
          {
            label: 'Products',
            icon: <RequestQuoteIcon />,
            linkTo: routes.carrierProducts.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.carrierGeneral,
        async () => await import('@/pages/systemManagement/CarrierGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.carrierProducts,
        async () =>
          await import('@/pages/systemManagement/CarrierProductsPage'),
      ),
    ],
  },
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.productGeneral.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.productGeneral,
        async () => await import('@/pages/systemManagement/ProductGeneralPage'),
      ),
    ],
  },
]
